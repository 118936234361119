import {Controller} from '@hotwired/stimulus'

export default class extends Controller {

    static targets = ["filters", "eventCardsContainer", "noMatchingEvents"];
    activeFilters = {};


    addFilter(filterName, filterValue) {
        this.activeFilters[filterName] = filterValue;
    }

    removeFilter(filterName) {
        delete this.activeFilters[filterName];
    }

    updateCards() {
        if (this.numberOfActiveFilters === 0) {
            this.eventCards.forEach(card => card.classList.remove('hide'));
            this.noMatchingEventsTarget.classList.add('hide');
            return;
        }
        let activeCardCount = 0;

        this.eventCards.forEach((card) => {
            const {state, type, audience, cost} = card.dataset;
            let matchedValues = [];

            const props = {
                state,
                type,
                audience,
                cost
            }

            for (const filter in this.activeFilters) {
                if (props[filter] === this.activeFilters[filter]) {
                    matchedValues.push(filter);
                }
            }

            let shouldShow = this.activeFilterNames.every(name => matchedValues.includes(name));

            if (shouldShow) {
                card.classList.remove('hide');
                activeCardCount++;
            } else {
                card.classList.add('hide');
            }

        });
        if (activeCardCount === 0) {
            this.noMatchingEventsTarget.classList.remove('hide');
        } else {
            this.noMatchingEventsTarget.classList.add('hide');
        }

    }

    mount() {
        this.filters.addEventListener('change', (e) => {

            let {value, name} = e.target;

            // The state select doesn't have a "real" "all" value, so this just ensures
            // we account for that
            value = value === '' || value === 'all' ? 'all' : value;

            if (value === 'all') {
                this.removeFilter(name);
            } else {
                this.addFilter(name, value);
            }

            this.updateCards();

        })
    }

    get numberOfActiveFilters() {
        return Object.keys(this.activeFilters).length;
    }

    get activeFilterNames() {
        return Object.keys(this.activeFilters);
    }

    get filters() {
        return this.filtersTarget;
    }

    get eventCards() {
        return Array.from(this.eventCardsContainerTarget.children);
    }

    connect() {
        this.mount();
    }

}
