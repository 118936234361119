import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['content', 'button'];
    static classes = ['open'];

    activeButton;
    activeContent;


    toggle({currentTarget, params}) {
        this.buttonTargets.forEach(el => {
            if (el !== currentTarget) {
                el.classList.remove(this.openClass);
            } else {
                this.activeButton = el;
                el.classList.toggle(this.openClass);
            }
        });

        this.contentTargets.forEach(el => {
            if (el.dataset.tabsId !== params.id) {
                el.classList.remove(this.openClass);
            } else {
                this.activeContent = el;
                el.classList.toggle(this.openClass);
                if (window.innerWidth < 768){
                    this.teleport();
                }
            }
        });
    }

    teleport(){
        if(this.activeContent.classList.contains(this.openClass)){
            this.teleportToButton();
        }
    }

    teleportToButton() {
        this.activeButton.parentNode.insertBefore(this.activeContent, this.activeButton.nextSibling);
    }

}
