import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

    static targets = [ "rootElement", "exitSiteButton" ]

    observer = null;

    /**
     * Re-positions the exit site button based on visibility of observed element
     *
     * @param { Array<IntersectionObserverEntity> } entries
     * @param { IntersectionObserver } observer
     *
     * @returns void
     */
    relocate( entries, observer ) {
        const percentVisible = entries[0].intersectionRatio;
        const classesToToggle = [ 'fixed', 'fade-in' ];
        const classListAction = percentVisible === 0 ? 'add' : 'remove';

        this.exitSiteButton.classList[classListAction]( ...classesToToggle );
    }

    /**
     * Opens a website in a new tab, then replaces the current tab with some
     * other site
     *
     * @returns void
     */
    exitSite() {
        window.open( "https://weather.com", "_newtab" );
        window.location.replace( "https://google.com" );
    }

    /**
     * Builds the intersection observer with some options and a callback
     *
     * @param { Object } observerOptions - options to pass into the observer constructor
     * @param { Function } observerCallback - the callback function called with each intersection event
     *
     * @returns { this }
     */
    buildObserver( observerOptions, observerCallback ) {

        const options = observerOptions || {
            rootMargin: '40px',
            threshold: 0
        }

        this.observer = new IntersectionObserver( ( entries, observer ) => {
            this.relocate( entries, observer );
        }, options)

        return this;
    }

    /**
     * Mounts the observer to some element
     *
     * @param { HTMLElement } element - the html element to observe
     *
     * @returns { this }
     */
    attachObserver( element ) {
        this.observer.observe(element);
        return this;
    }

    /**
     * Alias for this.rootElementTarget
     * @returns { HTMLElement }
     */
    get rootElement() {
        return this.rootElementTarget;
    }

    /**
     * Alias for this.exitSiteButtonTarget
     * @returns { HTMLElement }
     */
    get exitSiteButton() {
        return this.exitSiteButtonTarget;
    }

    set observer( intersectionObserver ) {
        this.observer = intersectionObserver;
    }

    connect() {
        this.buildObserver().attachObserver( this.rootElement );
    }

}
