import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['icon'];
    static values = {
        placeholder: String,
        fieldType: {
            type: String,
            default: "outline",
        }
    }

    connect() {
        const gsceInput = this.element.querySelector('input.gsc-input');
        gsceInput.placeholder = this.placeholderValue;
        const parent = gsceInput.parentElement;
        parent.classList.add('form-field', `form-field--${this.fieldTypeValue}`);
        if(!parent.querySelector('label.form-field__prefix')){
            const prefixEl = document.createElement('LABEL');
            prefixEl.classList.add('form-field__prefix', 'form-field__prefix--inset');
            prefixEl.innerHTML = this.iconTarget.innerHTML;
            parent.prepend(prefixEl);
        }
    }
}
