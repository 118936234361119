import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

    static targets = [ "frame", "activator", "frameTracker", "submitter" ];

    appendNewFrame() {

        const newFrame = document.createElement('turbo-frame');
        newFrame.id = this.nextFrameName;
        this.frameTarget.classList.forEach((className) => {
            newFrame.classList.add(className);
        })
        newFrame.setAttribute("data-utilities--appendable-turbo-frame-target", "frame")

        this.frameTargets.at(-1).after(newFrame);
    }

    redirectActivator() {
        this.activatorTarget.dataset.turboFrame = this.nextFrameName;
        this.frameTrackerTarget.value = this.nextFrameNumber;
    }

    attachListeners() {
        document.addEventListener('turbo:submit-start', (e) => {
            this.disableActivator();
        } );
        document.addEventListener("turbo:frame-render", (e) => {
            this.redirectActivator();
            this.enableActivator();
            this.appendNewFrame();
        })
    }

    enableActivator() {
        this.submitterTarget.disabled = false;
    }

    disableActivator() {
        this.submitterTarget.disabled = true;
    }

    get frameName() {
        const id = this.frameTargets.at(-1).id;
        const splitId = id.split("-")
        return splitId.slice(0, -1).join("-");
    }

    get nextFrameNumber() {
        return this.frameTargets.length + 1;
    }

    get nextFrameName() {
        return [ this.frameName, this.nextFrameNumber ].join("-");
    }

    connect() {
        this.attachListeners();
    }
}
