import { Controller } from '@hotwired/stimulus'
import Splide from '@splidejs/splide';

export default class extends Controller {

    static targets = [ "splideContainer", "splideTrack" ]
    static values = {
        requiresBuild: {
            type: Boolean,
            default: false,
        },
        splideOptions: {
            type: Object,
            default: {
                focusableNodes: false,
                pagination: false,
            }
        }
    }

    createSplideInstance() {
        const container = this.splideContainer;
        const options = this.splideOptions
        return new Splide( container, options );
    }

    convertToSplideContainer() {
        if( this.splideContainer.classList.contains('splide') ) return;
        this.splideContainer.classList.add('splide');
        return this;
    }

    createSplideList() {
        const listEl = document.createElement('div');
        listEl.classList.add('splide__list');
        listEl.setAttribute('data-slider-target', 'splideList')
        return listEl;
    }

    mountSplideList() {
        const listEl = this.createSplideList();
        const trackEl = this.splideTrack;
        trackEl.insertAdjacentElement( 'afterbegin', listEl );
        return this;
    }

    convertElementsToSplideSlides() {
        const elementsToConvert = Array.from(this.splideContainer.children);
        elementsToConvert.forEach( (el) => {
            if( !el.classList.contains('splide__slide')) {
                el.classList.add('splide__slide');
            }
            el.setAttribute('data-slider-target', 'splideSlide');
        })
        return elementsToConvert;
    }

    replaceWithSplideSlides( slides ) {
        const fragment = new DocumentFragment();
        slides.forEach( slide => fragment.append(slide) );
        this.splideList.append(fragment);
    }

    addSlidesToSplideList() {
        if( this.slidesAreAttachedToList ) return;
        this.splideSlides.forEach((slide) => {
            slide.remove();
            this.splideList.append(slide);
        })
        return this;
    }

    createSplideTrack() {
        const containerEl = document.createElement('div');
        containerEl.classList.add('splide__track');
        containerEl.setAttribute('data-slider-target', 'splideTrack')
        return containerEl;
    }

    mountSplideTrack() {
        const trackEl = this.createSplideTrack();
        const splideContainer = this.splideContainer;
        splideContainer.insertAdjacentElement( 'afterbegin', trackEl );
        return this;
    }

    restructureMarkup() {
        const convertedSlides = this.convertElementsToSplideSlides();
        this.convertToSplideContainer()
            .addButtons()
            .mountSplideTrack()
            .mountSplideList()
            .replaceWithSplideSlides( convertedSlides );
    }

    addButtons() {
        const container = document.createElement('div');
        container.classList.add('splide__arrows');
        container.innerHTML = this.buttonMarkup;
        this.splideContainer.insertAdjacentElement('afterbegin', container);
        return this;
    }

    get buttonMarkup() {
        return `<button class="splide__arrow splide__arrow--prev">
                    <svg width="17" height="15" viewBox="0 0 17 15" fill="none" style="transform: rotate(180deg)" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2.00001 7.77777L14.519 7.77777" stroke="white" stroke-width="2.25" stroke-linecap="round"/>
                      <path d="M9.22198 13.556L15 7.77802L9.22199 2" stroke="white" stroke-width="2.25" stroke-linecap="round"/>
                    </svg>
            </button>
            <button class="splide__arrow splide__arrow--next">
                <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.00001 7.77777L14.519 7.77777" stroke="white" stroke-width="2.25" stroke-linecap="round"/>
                  <path d="M9.22198 13.556L15 7.77802L9.22199 2" stroke="white" stroke-width="2.25" stroke-linecap="round"/>
                </svg>
            </button>`
    }

    get splideContainer() {

        if( this.hasSplideContainerTarget) {
            return this.splideContainerTarget;
        }

        return this.element;
    }

    get splideTrack() {
        if( this.hasSplideTrackTarget ) {
            return this.splideTrackTarget;
        }
        return this.splideContainer.querySelector('.splide__track');
    }

    get splideList() {
        if( this.hasSplideListTarget ) {
            return this.splideListTarget;
        }
        return this.splideContainer.querySelector('.splide__list');
    }

    get splideSlides() {
        if( this.hasSplideSlideTarget ) {
            return this.splideSlideTargets;
        }
        return Array.from(this.splideContainer.querySelectorAll('.splide__slide'));
    }

    get requiresBuild() {
        return this.requiresBuildValue
    }

    get splideOptions() {
        return this.splideOptionsValue;
    }

    connect() {
        if( this.requiresBuild ) {
            this.restructureMarkup();
        }
        const splide = this.createSplideInstance();
        splide.mount();
    }

}
