import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static values = {src: String, selectedType: String, ignoredIds: Array };
    static targets = [ "button" ];

    async fetchStream() {
        const resp = await fetch(this.url, {
            headers: {
                Accept: "text/vnd.turbo-stream.html",
            },
        });
        const html = await resp.text();
        this.#updateIgnored(html);
        Turbo.renderStreamMessage(html);
        this.#removeButtonIfNeeded(html);
    }

    get url(){
        const params = new URLSearchParams();
        if(this.hasSelectedTypeValue && this.selectedTypeValue !== 'all'){
            params.append('selected_type', this.selectedTypeValue);
        }

        for(const id of this.ignoredIdsValue){
            params.append('ignored_ids[]', id);
        }
        return `${this.srcValue}?${params.toString()}`;
    }

    #removeButtonIfNeeded(htmlString){
        if(this.#getNewIgnoredIds(htmlString).length < 6){
            this.buttonTarget.remove();
        }
    }

    #updateIgnored(htmlString){
        this.ignoredIdsValue = [...this.ignoredIdsValue, ...this.#getNewIgnoredIds(htmlString)];
    }

    #getNewIgnoredIds(htmlString) {
        const newIgnoredIds = [];
        const parsedHTML = htmlString.split('<template>')[1].split('</template>')[0];
        const templateEl = document.createElement('template');
        templateEl.innerHTML = parsedHTML;
        templateEl.content.querySelectorAll('a.resource-card').forEach(card => {
            const {id} = card.dataset;
            newIgnoredIds.push(parseInt(id));
        });
        return newIgnoredIds;
    }

}
