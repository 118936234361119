import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

    static targets = [ "menuActivator", "panel", "dropdownActivator", "mobileNav" ];
    static values = {
        menuOpen: Boolean,
        scrollBlocked: Boolean,
        activePanel: String,
        isDesktop: Boolean,
        pageIsBlurred: Boolean
    }

    /**
     * Close all open panels and reveal target panel
     *
     * @param { String } panelId - the id attribute of the panel to open (expects "#" to be excluded from id name)
     *
     * @returns { void }
     */
    revealPanel( panelId ) {

        const targetPanel = this.panelTargets.find((panelEl) => {
            return panelEl.id === panelId;
        })

        if( !targetPanel ) {
            console.warn(`Unable to find panel with id ${panelId}`);
            return;
        }

        this.closeAllPanels({ enablePageScroll: false });
        targetPanel.classList.toggle('hide');

        if( this.isDesktop ) {
            this.blurPage();
            this.blockPageScroll();
        }

    }

    /**
     * Updates and renders active menu/dropdown panel
     *
     * @param { Event } event
     *
     * @returns { void }
     */
    setActivePanel( event ) {
        let { currentTarget: button } = event;
        const targetPanel = button.dataset.panelTarget;
        this.activePanel = targetPanel;
        this.revealPanel(targetPanel);
    }

    /**
     * Responsible for closing all open panels and resetting some state.
     *
     * @param { { enablePageScroll: boolean } } options
     *
     * @returns { void }
     */
    closeAllPanels( options={} ) {

        let enablePageScroll = true;

        if( typeof options.enablePageScroll === 'boolean' ) {
            enablePageScroll = options.enablePageScroll
        }

        this.panelTargets.forEach((panel) => {
            if( !panel.classList.contains('hide') ) panel.classList.add('hide');
        })

        this.unblurPage();
        this.resetActivePanel();

        if( enablePageScroll ) {
            this.enablePageScroll();
        }

    }

    /**
     * Resets active panel
     *
     * @returns { void }
     */
    resetActivePanel() {
        this.activePanel = "";
    }

    /**
     * Open/close mobile menu
     *
     * @returns { void }
     */
    toggleMenu() {

        if( !this.menuIsOpen ) {
            this.revealPanel('root');
            this.activePanel = "root";
            this.blockPageScroll();
        } else {
            this.resetActivePanel();
            this.closeAllPanels({ enablePageScroll: true});
        }

        this.menuOpenValue = !this.menuOpenValue;
    }

    /**
     * Adds scroll-blocking utility class to body
     *
     * @returns { void }
     */
    blockPageScroll() {
        if( this.scrollIsBlocked ) return;

        document.body.classList.add('block-scroll');
        this.scrollBlockedValue = true;
    }

    /**
     * Removes scroll-blocking utility class from body
     *
     * @returns { void }
     */
    enablePageScroll() {
        if( !this.scrollIsBlocked ) return;

        document.body.classList.remove('block-scroll');
        this.scrollBlockedValue = false;
    }

    /**
     * Reveals root menu panel
     *
     * @returns { void }
     */
    returnToMainMenu() {
        this.revealPanel("root");
    }

    /**
     * Adds page blur effect
     *
     * @returns { void }
     */
    blurPage() {
        if( this.pageIsBlurred ) return;
        document.querySelector('.page-blur').classList.remove("hide");
        this.pageIsBlurred = true;
    }

    /**
     * Removes page blur effect
     *
     * @returns { void }
     */
    unblurPage() {
        if( !this.pageIsBlurred ) return;

        document.querySelector('.page-blur').classList.add("hide");
        this.pageIsBlurred = false;
    }

    /**
     * Attaches event listener to .page-blur element to close all panels if blur is clicked
     *
     * @returns { void }
     */
    listenForClickOutsidePanel() {
        document.querySelector('.page-blur').addEventListener('click', (e) => {
            this.closeAllPanels();
        })
    }

    /**
     * Alias for this.pageIsBlurredValue
     *
     * @returns { Boolean }
     */
    get pageIsBlurred() {
        return this.pageIsBlurredValue;
    }

    /**
     * Alias for this.menuOpenValue
     * @returns { Boolean }
     */
    get menuIsOpen() {
        return this.menuOpenValue;
    }

    /**
     * Alias for this.scrollBlockedValue
     * @returns { Boolean }
     */
    get scrollIsBlocked() {
        return this.scrollBlockedValue;
    }

    /**
     * Alias for this.panelTargets
     * @returns { Array<HTMLElement> } - an array of HTML elements
     */
    get panels() {
        return this.panelTargets;
    }

    /**
     * Alias for this.isDesktopValue
     * @returns { Boolean }
     */
    get isDesktop() {
        return this.isDesktopValue;
    }

    set pageIsBlurred( bool ) {
        this.pageIsBlurredValue = bool;
    }

    set activePanel( panelId ) {
        this.activePanelValue = panelId;
    }

    connect() {
        this.isDesktopValue = window.innerWidth > 1000;
        this.listenForClickOutsidePanel();
    }
}
