import { Controller } from '@hotwired/stimulus'
import tippy from 'tippy.js';

export default class extends Controller {

    static values = {
        tooltipText: String,
        options: Object
    }

    get tooltipText() {
        return this.tooltipTextValue
    }

    get options() {
        return this.optionsValue
    }

    connect() {

        tippy(this.element, {
            ...this.options,
            content: this.tooltipText
        })
    }

}
