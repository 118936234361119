import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
    static classes = ["toggle"];
    static targets = ["element"]
    toggle(event){
        if(this.hasElementTarget){
            this.elementTargets.forEach(target => target.classList.toggle(this.toggleClass));
        } else {
            this.element.classList.toggle(this.toggleClass);
        }
        this.#toggleAriaPressed(event.currentTarget);
    }

    #toggleAriaPressed(el){
        const currentState = el.ariaPressed;
        if(currentState === 'true'){
            el.ariaPressed = 'false';
        } else {
            el.ariaPressed = 'true';
        }
    }

}
