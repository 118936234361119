import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
    static targets = [ "content" ];
    static classes = ["open"];

    close(){
        this.element.classList.remove(this.openClass);
    }

    toggle(){
        this.element.classList.toggle(this.openClass);
    }
}
