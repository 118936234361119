import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ["toast"];

    copyToClipboard() {
        const currentUrl = window.location.href;
        navigator.clipboard.writeText(currentUrl);
    }

    showToastMessage() {
        this.toastTarget.classList.add('show');
        setTimeout(() => {
            this.toastTarget.classList.remove('show')
        }, 3000);
    }

    share() {
        this.copyToClipboard();
        this.showToastMessage();
    }
}
