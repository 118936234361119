import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
    static targets = ["dropdown"];

    close(event){
        this.dropdownTargets.forEach(target => {
            if(!target.contains(event.target)){
                const dropdownController = this.application.getControllerForElementAndIdentifier(target, 'dropdown');
                dropdownController.close();
            }
        })
    }
}
